import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import LayoutImages from "../components/image/LayoutImages"
import EditMenu from "../components/image/EditMenu"
import MenuSubdir from "../components/image/MenuSubdir"
import ColumnView from "../components/view/columnView"

const Page = ({location}) => {
  return(
    <Layout>
      <SEO title="Columns view" />
      <LayoutImages>
        <EditMenu />
        <MenuSubdir />
        <ColumnView />
      </LayoutImages>    
    </Layout>
  )  
}

export default Page


